import "./App.css";
import ConvertPdf from "./actions/ConvertPdf";
import { pdf } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

function App() {
  const [gst, setGst] = useState(false);
  const [rto, setRto] = useState("");
  const [rno, setRno] = useState("");
  const [date, setDate] = useState("");
  const [ddate, setDDate] = useState("");
  const [sname, setSname] = useState("");
  const [sclass, setSclass] = useState("");
  const [tfee, setTfee] = useState("");
  const [ino, setIno] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [pfee, setPFee] = useState("");

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (
      rto !== "" &&
      rno !== "" &&
      date !== "" &&
      sname !== "" &&
      sclass !== "" &&
      ino !== "" &&
      tfee !== "" &&
      pfee !== ""
    ) {
      if (gst) {
        if (gstNo !== "") {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      } else if(pfee!=="0"){
        if(ddate!==""){
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      } 
      else {
        setDisabled(false);
      }
    } else {
      setDisabled(true);
    }
  }, [rto, rno, date, sclass, sname, tfee, gst, gstNo, ino, pfee, ddate]);

  const SaveSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#fff"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z" />
    </svg>
  );

  const SaveDisabledSVG = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#4d4d4d"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z" />
    </svg>
  );

  const appendSpreadsheet = async () => {
    await fetch("https://api.apispreadsheets.com/data/Cujfwthu2Ch5EBwZ/", {
      method: "POST",
      body: JSON.stringify({
        data: {
          "Receipt To": rto,
          "Receipt No": rno,
          "Student Class": sclass,
          Date: moment(date).format("DD/MM/YYYY"),
          "Student Name": sname,
          "Tutition Fees": tfee,
          "Installment Number": ino,
          GST: gstNo,
          "Pending Fee": pfee,
          "Next Due Date": pfee!=="0" ? moment(ddate).format("DD/MM/YYYY"):"Closed",
        },
      }),
    }).then((res) => {
      if (res.status === 201) {
        console.log("Success");
      } else {
        console.log("ERROR");
      }
    });
  };

  const convertPdf = () => {
    const myDoc = (
      <ConvertPdf
        rto={rto}
        rno={rno}
        date={date}
        sname={sname}
        sclass={sclass}
        tfee={tfee}
        gst={gst}
        gstNo={gstNo}
        ino={ino}
        pfee={pfee}
        ddate={ddate}
      />
    );

    const blob = pdf(myDoc)
      .toBlob()
      .then((res) => {
        var fileURL = URL.createObjectURL(res);
        const a = document.createElement("a");
        a.href = fileURL;
        a.download = `${sname}_${date}_receipt.pdf`;
        var file = new File([res], sname + ".pdf");
        console.log(file);

        const data = new FormData();
        data.append("file", file);
        data.append("sname", sname);
        data.append("sclass", sclass);
        data.append("ino", ino);
        console.log(file);
        axios
          .post(
            "https://learningroomdigital-website.uc.r.appspot.com/uploadFileAPIReceipt",
             data
          )
          .then((res) => {
            // then print response status
            console.log(res.statusText);
          });

        a.click();
      })
      .then(() => {
        setRto("");
        setRno("");
        setDate("");
        setSname("");
        setSclass("");
        setTfee("");
        setPFee("")
        setDDate("");
        setIno("");
        setDisabled(true);
        setSclass("");
      });
    var fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
    window.location.reload();
  };

  return (
    <>
      <div className="App">
        <div className="header">
          <img src={"./logo_cut.webp"} alt="" />
          <div className="hbg"></div>
        </div>
        <div className="content">
          <form>
            <label htmlFor="rto">
              <p>Receipt To</p>{" "}
              <input
                type="text"
                id="rto"
                name="Receipt To"
                placeholder="Name"
                value={rto}
                onChange={(e) => setRto(e.target.value)}
              />
            </label>
            <label htmlFor="rno">
              <p>Receipt No</p>{" "}
              <input
                type="text"
                id="rno"
                name="Receipt No"
                placeholder="12345"
                value={rno}
                onChange={(e) => setRno(e.target.value)}
              />
            </label>
            <label htmlFor="date">
              <p>Date</p>{" "}
              <input
                type="date"
                id="date"
                name="Date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </label>
            <label htmlFor="sname">
              <p>Student Name</p>{" "}
              <input
                type="text"
                id="sname"
                name="Student Name"
                placeholder="Name"
                value={sname}
                onChange={(e) => setSname(e.target.value)}
              />
            </label>
            <label htmlFor="sclass">
              <p>Student Class</p>{" "}
              <input
                type="text"
                id="sclass"
                name="Student Class"
                placeholder="Class"
                value={sclass}
                onChange={(e) => setSclass(e.target.value)}
              />
            </label>
            <label htmlFor="tfee">
              <p>Tuition Fees</p>{" "}
              <div
                style={{
                  display: "grid",
                  width: "100%",
                  gridTemplateColumns: "1fr 5fr",
                }}
              >
                <p
                  style={{
                    padding: "10px",
                    backgroundColor: "#cccccc",
                    borderRadius: "5px",
                    marginRight: "10px",
                    border: "1px solid #838383",
                  }}
                >
                  INR
                </p>
                <input
                  type="text"
                  id="tfee"
                  name="Tuition Fees"
                  placeholder="000"
                  value={tfee}
                  onChange={(e) => setTfee(e.target.value)}
                />
              </div>
            </label>
            <label htmlFor="ino">
              <p>Installment Number</p>
              <input
                type="text"
                id="ino"
                name="Installment Number"
                placeholder="1 / 2 / 3 / 4 / 5 / 6"
                value={ino}
                onChange={(e) => setIno(e.target.value)}
              />
            </label>
            <label htmlFor="pfee">
              <p>Pending Fee</p>
              <input
                type="text"
                id="pfee"
                name="Pending Fee"
                placeholder="Pending Fee"
                value={pfee}
                onChange={(e) => setPFee(e.target.value)}
              />
            </label>
            {pfee!=="0"?(<label htmlFor="ddate">
              <p>Next Due Date</p>
              <input
                type="date"
                id="ddate"
                name="Due Date"
                placeholder="Next Due Date"
                value={ddate}
                onChange={(e) => setDDate(e.target.value)}
              />
            </label>):""}
            <label htmlFor="tfee">
              <p>GST</p>{" "}
              <input
                type="checkbox"
                id="gst"
                onChange={() => {
                  setGst(!gst);
                }}
              />
            </label>
            {gst && (
              <label htmlFor="gstNo" style={{ marginBottom: "20px" }}>
                <p>GST Amount</p>{" "}
                <div
                  style={{
                    display: "grid",
                    width: "100%",
                    gridTemplateColumns: "1fr 5fr",
                  }}
                >
                  <p
                    style={{
                      padding: "10px",
                      backgroundColor: "#cccccc",
                      borderRadius: "5px",
                      marginRight: "10px",
                      border: "1px solid #838383",
                    }}
                  >
                    INR
                  </p>
                  <input
                    type="text"
                    id="gstNo"
                    name="GST"
                    placeholder="000"
                    value={gstNo}
                    onChange={(e) => setGstNo(e.target.value)}
                  />
                </div>
              </label>
            )}
          </form>

          <div className="buttons">
            <button
              disabled={disabled}
              onClick={() => {
                appendSpreadsheet();
                convertPdf();
              }}
            >
              Save PDF{disabled ? <SaveDisabledSVG /> : <SaveSVG />}
            </button>
          </div>
        </div>
      </div>
      <div className="footer">
      <p>
          <span className="colorSpan">Learning Room</span> All rights reserved
          <span className="marginSpan">|</span> Developed and Managed by
          <a href="https://centigrade.ml"> Centigrade</a>
        </p>
      </div>
    </>
  );
}

export default App;
