import React from "react";
import {
  Document,
  View,
  Page,
  Image,
  Text,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import LeagueSpartan from "../fonts/leaguespartan-bold.ttf";
import LeagueSpartanRegular from "../fonts/LeagueSpartan-Regular.ttf";

const ConvertPdf = ({
  rto = "",
  rno = "",
  date = "",
  sname = "",
  sclass = "",
  tfee = "",
  gst = false,
  gstNo = "",
  ino = "",
  pfee="",
  ddate=""
}) => {
  Font.register({ family: "League Spartan", src: LeagueSpartan });
  Font.register({ family: "League Spartan R", src: LeagueSpartanRegular });


  const styles = StyleSheet.create({
    image: {
      width: 150,
      height: 150,
      marginLeft: 50,
      marginTop: 0,
    },
    header: {
      marginLeft: 240,
      marginTop: -120,
      fontSize: 12,
      color: "#000",
      fontFamily: "League Spartan",
      paddingRight: 20,
    },
    details: {
      paddingLeft: 50,
      paddingRight: 50,
      marginTop: 10,
    },
    fields: {
      paddingTop: 30,
      fontSize: 13,
      color: "#011A3B",
      fontFamily: "League Spartan",
      width: "110px",
    },
    footer: {
      marginTop: 10,
      fontSize: 20,
      fontFamily: "League Spartan",
    },
    dot: {
      margin: "auto",
      fontSize: 12,
    },
  });

  const Field = ({ label, out }) => (
    <View
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
      }}
    >
      <Text style={styles.fields}>{label}</Text>

      <View
        style={{
          paddingTop: 30,
          width: "350px",
          display: "flex",
          alignItems: "baseline",
        }}
      >
        <Text
          style={{
            width: "350px",
            paddingLeft: "10px",
            borderBottom: "2px solid #03234c",
            fontFamily: "League Spartan R",
          }}
        >
          {out}
        </Text>
      </View>
    </View>
  );
  return (
    <Document>
      <Page size="A4" style={{ position: "relative" }}>
        <View
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <Image style={{ width: "100%" }} source={"./receipt-bg.png"} />
        </View>
        <View
          style={{
            display: "flex",
            height: "20px",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              display: "flex",
              height: "20px",
              width: "60%",
              backgroundColor: "#03234c",
            }}
          ></View>
          <View
            style={{
              display: "flex",
              height: "20px",
              width: "38%",
              backgroundColor: "#03234c",
            }}
          ></View>
        </View>

        <View>
          <Image style={styles.image} source={"./logo(epsilon).png"} />
          <View style={styles.header}>
            <Text>Contact {"  "} | 97910-90086</Text>
            <Text>
              Email {"  "}
              {"  "} {"  "}| office@learningroomdigital.com
            </Text>
            <Text>
              Address{"  "}| Door E, Plot 23, 2nd Main Road, VGP Selva Nagar,
              Velachery, Chennai-600042 {"\n"}
              {gst && "GSTIN  | 332200054727TRN"}
            </Text>
            {/* {gst && <Text>GSTIN {"   "}| 332200054727TRN</Text>} */}
          </View>
        </View>
        <View style={styles.details}>
          <Field label={"RECEIPT TO"} out={rto} />
          <Field label={"RECEIPT NO"} out={rno} />
          <Field label={"DATE"} out={moment(date).format("DD/MM/YYYY")} />
          <Field label={"STUDENT NAME"} out={sname} />
          <Field label={"STUDENT CLASS"} out={sclass} />
          <Field label={"TUITION FEE"} out={tfee + " INR"} />
          <Field label={"STUDENT NAME"} out={sname} />
          {gst && <Field label={"GST"} out={gstNo} />}
          <Field label={"INSTALLMENT NUMBER"} out={ino} />
          <Field label={"PENDING FEE"} out={pfee} />
          {pfee!=="0"?(<Field label={"NEXT DUE DATE"} out={moment(ddate).format("DD/MM/YYYY")} />):null}
          <View
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Text style={styles.fields}>AUTHORISED SIGNATURE</Text>

            <View
              style={{
                paddingTop: 30,
                width: "350px",
                display: "flex",
                alignItems: "baseline",
              }}
            >
           
              <Image source={"./sign.png"} style={{ width: "100px" }} />
              <View
                style={{ width: "350px", borderBottom: "2px solid #03234c" }}
              ></View>
             
            </View>
          </View>
        </View>
        <View style={styles.footer}>
          <Text style={{ margin: "auto", marginBottom: 10, fontSize: 18 }}>
            ..........................................................................
          </Text>
          <Text style={{ margin: "auto", fontSize: 14 }}>
            End of the Report
          </Text>
        </View>
        <View
          style={{
            position: "absolute",
            display: "flex",
            height: "20px",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            bottom: 0,
          }}
        >
          <View
            style={{
              display: "flex",
              height: "20px",
              width: "38%",
              backgroundColor: "#03234c",
            }}
          ></View>
          <View
            style={{
              display: "flex",
              height: "20px",
              width: "60%",
              backgroundColor: "#03234c",
            }}
          ></View>
        </View>
      </Page>
    </Document>
  );
};

export default ConvertPdf;
